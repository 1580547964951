import { RangeValue } from '@core-ui/datepicker/rc-picker/interface';
import { MONTH_YEAR_DATE_FORMAT } from 'app/consts/app';
import { IRangeFilterFormatArray } from 'app/types/common';
import { getEndOfCurrentYear, getStartOfCurrentYear } from 'app/utils/dates';

// TODO: переделать в капс
export const articlesContainerWidth = 298;
export const totalItemWidth = 144;
export const articleItemWidth = 292;
export const monthItemWidth = 120;
export const currencyId = 103;
export const ARTICLES_CONTAINER_WIDTH = 298;
export const TOTAL_ITEM_WIDTH = 144;
export const ARTICLE_ITEM_WIDTH = 292;
export const MONTH_ITEM_WIDTH = 120;

// budgets currently support only one currency - EUR
export const BUDGET_CURRENCY_ID = 103;

export enum FINANCES_TABS {
  BUDGET = 'budget',
}

export const FINANCES_PATH_TABS = Object.values(FINANCES_TABS).join('|');

export const ENTER_KEY = 'Enter';

export const BUDGET_HEADER_HEIGHT = 48;
export const BUDGET_FILTERS_HEIGHT = 84;

export const BUDGETS_DEFAULT_FILTERS_DATE_RANGE_ARRAY: RangeValue<Date> = [
  getStartOfCurrentYear(),
  getEndOfCurrentYear(),
];

export const BUDGETS_FILTERS_DATE_RANGE_FORMAT_ARRAY: IRangeFilterFormatArray = [
  MONTH_YEAR_DATE_FORMAT,
  MONTH_YEAR_DATE_FORMAT,
];
