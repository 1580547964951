import { AmountFormatter } from '@/app/types/common';
import { Nullable } from '@core-ui/types';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { FormattedMessage } from 'components/Intl';
import { useAppSelector } from 'hooks/useAppSelector';
import isNil from 'lodash-es/isNil';
import { BUDGET_TABLE_CONTAINER_ID } from 'pages/finances/budget/BudgetTable/BudgetTable';
import { ARTICLES_CONTAINER_WIDTH, MONTH_ITEM_WIDTH, TOTAL_ITEM_WIDTH } from 'pages/finances/consts';
import { useEffect, useRef, useState } from 'react';
import { FieldArray } from 'react-final-form-arrays';
import TotalMonthCell from '../TotalMonthCell';
import selector from './selector';
import useStyles from './styles';

interface IProps {
  amountFormatter: AmountFormatter;
}

const BudgetHeader = (props: IProps) => {
  const { amountFormatter } = props;

  const { headers, headersLength, total } = useAppSelector(selector);
  const [totalColumnWidth, setTotalColumnWidth] = useState<number | undefined>();

  const styles = useStyles();
  const headerContainerRef = useRef<Nullable<HTMLDivElement>>(null);

  useEffect(() => {
    if (isNil(headersLength) || headersLength === 0) {
      return;
    }

    const budgetTableContainer = document.getElementById(BUDGET_TABLE_CONTAINER_ID);
    const budgetTableContainerWidth = budgetTableContainer?.clientWidth || 0;
    // calculate header container width as if total column has default width to avoid positive feedback loop
    const headerContainerWidth =
      (headerContainerRef.current?.clientWidth ?? 0) - (totalColumnWidth ? totalColumnWidth + TOTAL_ITEM_WIDTH : 0);
    const numberOfMonths = headersLength ?? 0;

    if (headerContainerWidth <= budgetTableContainerWidth) {
      const newWidth = budgetTableContainerWidth - (numberOfMonths * MONTH_ITEM_WIDTH + ARTICLES_CONTAINER_WIDTH);
      setTotalColumnWidth(newWidth);
    }
  }, [headersLength]);

  return (
    <Grid
      container
      wrap="nowrap"
      flexDirection="column"
      top={0}
      position="sticky"
      zIndex={2}
      sx={styles.container}
      ref={headerContainerRef}
    >
      <Grid container wrap="nowrap">
        <Grid container sx={styles.costArticleContainer}>
          <Grid item sx={styles.costArticleColor} />

          <Grid item container sx={styles.costArticleName} alignItems="center">
            <Typography variant="body1" fontWeight="semiBold" paddingLeft={2}>
              <FormattedMessage id="cost.article" />
            </Typography>
          </Grid>
        </Grid>

        {headers?.map((header) => (
          <Grid item container key={header.name} sx={styles.monthName} alignItems="center">
            <Typography variant="body1" fontWeight="semiBold" paddingLeft={2}>
              {header.name}
            </Typography>
          </Grid>
        ))}

        <Grid
          item
          container
          sx={styles.totalNameHeader}
          alignItems="center"
          justifyContent="flex-end"
          width={totalColumnWidth}
        >
          <Typography variant="body1" fontWeight="semiBold" paddingRight={3} align="right">
            <FormattedMessage id="label.total" />
          </Typography>
        </Grid>
      </Grid>

      <Grid container wrap="nowrap">
        <Grid container sx={styles.totalContainer}>
          <Grid item sx={styles.totalColor} />

          <Grid item container sx={styles.totalName} alignItems="center">
            <Typography variant="body1" paddingLeft={2}>
              <FormattedMessage id="table.header.total" />
            </Typography>
          </Grid>
        </Grid>

        <FieldArray
          name="monthSubtotal"
          subscription={{ length: true }}
          render={({ fields }) => {
            return fields.map((totalPathValues) => (
              <TotalMonthCell key={totalPathValues} path={totalPathValues} amountFormatter={amountFormatter} />
            ));
          }}
        />

        <Grid item container sx={styles.totalCell} alignItems="center" justifyContent="flex-end">
          <Typography variant="body1" paddingRight={3} align="right">
            {total}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default BudgetHeader;
