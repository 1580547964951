import State from '@/app/types/state';
import { getAmountFormatter } from '@/dictionary/selector';
import { getBudgetForm } from '@/pages/finances/budget/BudgetTable/selector';
import { createSelector } from 'reselect';

// total_budgets - это редактируемые бюджеты которые планировались как верхние границы бюджетов, в итоге оказались не нужны
// код оставлен т.к. возможно придётся их возвращать в немного другом виде
// const getTotalBudgets = (state: State) => state.finances.budgets.value?.total_budgets;
// const getAbsoluteTotal = createSelector([getTotalBudgets, getAmountFormatter], (totalBudgets, numberFormatter) =>
//   numberFormatter(totalBudgets!.reduce((acc, curr) => acc + (curr?.budget ?? 0), 0))
// );

const getHeaders = (state: State) => state.finances.budgets?.headers;
const getTotal = createSelector([getBudgetForm], (budgetForm) => {
  return budgetForm?.rows.reduce((total, row) => {
    // calculate total only from root row values because they already include all the children values
    if (row.isRootRow) {
      return total + row.total;
    }

    return total;
  }, 0);
});

export default createSelector([getTotal, getHeaders, getAmountFormatter], (total, headers, amountFormatter) => ({
  total: amountFormatter(total),
  headers,
  headersLength: headers?.length,
}));
