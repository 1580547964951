import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import SvgIcon from '@mui/material/SvgIcon';
import Typography from '@mui/material/Typography';
import { convertValueToUTCDate, formatTime } from '@/app/utils/dates';
import { getAmountFormatter } from '@/dictionary/selector';
import { BudgetHistorySchema } from '@/generated';
import { useAppSelector } from '@/hooks/useAppSelector';
import { ArrowRight } from '@phosphor-icons/react';
import isNil from 'lodash-es/isNil';
import React from 'react';
import { FormattedMessage } from 'components/Intl';

interface IProps {
  item: BudgetHistorySchema;
}

const BudgetHistoryItem = (props: IProps) => {
  const { item } = props;

  const amountFormatter = useAppSelector(getAmountFormatter);

  const cellEditedAt = formatTime(convertValueToUTCDate(item.created_at), { dateFormat: 'dd, MMM yyyy, HH:mm' });

  const cellName = item.cost_article_name ?? <FormattedMessage id="label.total" />;

  const cellMonth = item.month ? (
    // we subtract 1 because formatTime accepts months from 0 to 11, but our backend uses 1 to 12
    formatTime(new Date().setMonth(item.month - 1), { dateFormat: 'MMM' })
  ) : (
    <FormattedMessage id="label.no_info" />
  );

  const cellYear = item.year ?? <FormattedMessage id="label.no_info" />;

  let budgetBefore: JSX.Element | string = <FormattedMessage id="label.zero_with_dot" />;
  let budgetAfter: JSX.Element | string = <FormattedMessage id="label.zero_with_dot" />;

  // if there are no cost article it means that the Total cell was edited
  if (item.cost_article_id) {
    if (!isNil(item.budget_old_value)) {
      budgetBefore = amountFormatter(item.budget_old_value);
    }

    if (!isNil(item.budget_target_value)) {
      budgetAfter = amountFormatter(item.budget_target_value);
    }
  } else {
    if (!isNil(item.budget_old_total_value)) {
      budgetBefore = amountFormatter(item.budget_old_total_value);
    }

    if (!isNil(item.budget_target_total_value)) {
      budgetAfter = amountFormatter(item.budget_target_total_value);
    }
  }

  return (
    <Grid item container direction="column" paddingY={6} paddingX={11} component={Paper}>
      <Grid item>
        <Typography fontWeight="semiBold">
          {item.employee_firstname} {item.employee_lastname}
        </Typography>
      </Grid>

      <Grid item marginTop={1}>
        <Typography color="text.secondary" fontSize="small">
          {cellEditedAt}
        </Typography>
      </Grid>

      <Grid item marginTop={2}>
        <Typography>
          {cellName}, {cellMonth}, {cellYear}
        </Typography>
      </Grid>

      <Grid item marginTop={2} container gap={2}>
        <Typography color="text.secondary">{budgetBefore}</Typography>

        <SvgIcon>
          <ArrowRight />
        </SvgIcon>

        {budgetAfter}
      </Grid>
    </Grid>
  );
};

export default BudgetHistoryItem;
