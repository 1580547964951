import { AmountFormatter } from '@/app/types/common';
import { TextFieldMaskedForm } from '@/components/Form';
import { useAppSelector } from '@/hooks/useAppSelector';
import { getCellsDisabled } from '@/pages/finances/budget/BudgetRoot/selector';
import { BUDGET_FORM } from '@/pages/finances/budget/BudgetTable/BudgetTable';
import { ENTER_KEY, MONTH_ITEM_WIDTH } from '@/pages/finances/consts';
import { IBudgetForm } from '@/pages/finances/types';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { FormState } from 'final-form';
import get from 'lodash-es/get';
import isNil from 'lodash-es/isNil';
import { useMemo, useState } from 'react';
import { Field } from 'react-final-form';
import { useFormSelector } from 'react-final-form-selector';
import { useDispatch } from 'react-redux';
import { saveBudget } from '../../../BudgetRoot/actions';
import { formSelector } from './selector';
import useStyles from './styles';

interface IProps {
  rowPath: string;
  amountFormatter: AmountFormatter;
  isEditable: boolean;
}

const MonthCell = (props: IProps) => {
  const { rowPath, amountFormatter, isEditable } = props;

  const [activeInput, setActiveInput] = useState(false);

  const memoizedSelector = useMemo(formSelector, []);
  const cellData = useFormSelector((state: FormState<IBudgetForm>) => memoizedSelector(state, { rowPath }));

  const memoizedAppSelector = useMemo(getCellsDisabled, []);
  const { oceanCostArticlesEditDisabled } = useAppSelector(memoizedAppSelector);

  const dispatch = useDispatch();

  // const currentDate = new Date();
  // const cellDate = new Date(cellData.year, cellData.month);

  const isCellFieldActive = !oceanCostArticlesEditDisabled && isEditable; // && cellDate > currentDate;

  const styles = useStyles({ isCellFieldActive });

  const handleToggleInput = () => {
    if (isCellFieldActive) {
      setActiveInput((prevState) => !prevState);
    }
  };

  const handleOnBlur = () => {
    dispatch(saveBudget(cellData));
    setActiveInput(false);
  };

  const handleOnKeyDown = (event: KeyboardEvent) => {
    if (event.key === ENTER_KEY) {
      dispatch(saveBudget(cellData));
      setActiveInput(false);
    }
  };

  const budgetPrice = get(BUDGET_FORM.getState().values, `${rowPath}.budget`);

  return (
    <Grid position="relative">
      <Grid container sx={styles.totalCell} alignItems="center" width={MONTH_ITEM_WIDTH} onClick={handleToggleInput}>
        <Typography paddingLeft={2}>{!isNil(budgetPrice) ? amountFormatter(budgetPrice) : ''}</Typography>
      </Grid>

      {activeInput && (
        <ClickAwayListener onClickAway={handleToggleInput}>
          <span>
            <Field
              name={`${rowPath}.budget`}
              component={TextFieldMaskedForm}
              size="small"
              sx={styles.totalActive}
              autoFocus
              formatOnBlur
              onBlur={handleOnBlur}
              onKeyDown={handleOnKeyDown}
            />
          </span>
        </ClickAwayListener>
      )}
    </Grid>
  );
};

export default MonthCell;
