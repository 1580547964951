import { COLOURS, HEIGHT_SIZE } from '@core-ui/styles';
import { DYNAMIC_BOX_SHADOW } from '@core-ui/table';
import { SxProps, Theme } from '@mui/material/styles';
import { ARTICLE_ITEM_WIDTH, ARTICLES_CONTAINER_WIDTH, TOTAL_ITEM_WIDTH } from '@/pages/finances/consts';

const border = `1px solid ${COLOURS.BORDER.MAIN}`;

export default (props: { color: string; isRootRow: boolean }): Record<string, SxProps<Theme>> => ({
  costArticleContainer: {
    flex: `0 0 ${ARTICLES_CONTAINER_WIDTH}px`,
    position: 'sticky',
    zIndex: 1,
    left: 0,
    backgroundColor: COLOURS.Coconut.Solid[100],

    ...DYNAMIC_BOX_SHADOW.dynamicRight,
  },

  costArticleColor: {
    borderBottom: border,
    borderRight: border,
    borderLeft: border,
    width: 6,
    backgroundColor: props.color,
    height: HEIGHT_SIZE.headerHeight,
  },

  costArticleName: {
    borderBottom: border,
    borderRight: border,
    width: ARTICLE_ITEM_WIDTH,
    height: HEIGHT_SIZE.headerHeight,
  },

  total: {
    borderBottom: border,
    borderRight: border,
    height: HEIGHT_SIZE.headerHeight,
    width: TOTAL_ITEM_WIDTH,
    flex: `1 0 ${TOTAL_ITEM_WIDTH}px`,
    position: 'sticky',
    zIndex: 1,
    right: 0,
    backgroundColor: COLOURS.Coconut.Solid[100],

    ...DYNAMIC_BOX_SHADOW.dynamicLeft,
  },
});
