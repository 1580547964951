import { COLOURS, HEIGHT_SIZE } from '@core-ui/styles';
import { MONTH_ITEM_WIDTH } from '@/pages/finances/consts';

const offset = 10;
const border = `1px solid ${COLOURS.BORDER.MAIN}`;

export default () => ({
  totalCell: {
    borderTop: border,
    borderBottom: `3px solid ${COLOURS.BORDER.MAIN}`,
    borderRight: border,
    height: HEIGHT_SIZE.headerHeight,
    backgroundColor: COLOURS.Coconut.Solid[100],
    color: COLOURS.Coconut.Solid[900],
  },
  totalActive: {
    position: 'absolute',
    top: 0,
    left: -offset / 2,
    zIndex: 1,

    '.MuiOutlinedInput-root': {
      width: MONTH_ITEM_WIDTH + offset,
      height: HEIGHT_SIZE.headerHeight,
    },
  },
});
